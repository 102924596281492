<template>
  <div id="main">
    <DFSHeader />
    <AlignResultContent />
    <DFSFooter />
  </div>
</template>

<script>
import DFSHeader from '@/components/DFSHeader';
import DFSFooter from '@/components/DFSFooter';
import AlignResultContent from './components/AlignResultContent.vue';

export default {
  name: 'AlignResult',
  components: {
    DFSHeader,
    DFSFooter,
    AlignResultContent,
  },
  data() {
    return {
      running: true
    };
  }
}
</script>
