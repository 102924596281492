<template>
  <div id="main-content">
    <a-row v-if="qseqids.length > 0">
      <a-col :span="6">
        <a-card title="序列">
          <ul class="seqs">
            <li
              v-for="item in qseqids"
              :key="item"
              @click="onChangeQseqid(item)"
            >
              <span v-if="item === qseqid">
                <a-icon type="arrow-right" />&nbsp;{{item}}
              </span>
              <span v-else>{{item}}</span>
            </li>
          </ul>
        </a-card>
      </a-col>
      <a-col :span="18" style="padding-left: 1rem">
        <a-card title="比对结果">
          <a-table
            :columns="columns"
            :data-source="align"
            :row-key="record => record.staxid"
            :pagination="false"
            :loading="loading"
            bordered
          >
            <span slot="staxid" slot-scope="staxid">
              <a :href="'/species/' + staxid" target="_blank">{{staxid}}</a>
            </span>
            <template slot="ssciname" slot-scope="ssciname">
              <span v-html="ssciname"></span>
            </template>
          </a-table>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {getAlignResult} from '@/services/align';


export default {
  name: 'AlignResultContent',
  data() {
    return {
      columns: [
        {title: '编号', dataIndex: 'staxid', scopedSlots: {customRender: 'staxid'}},
        {title: '中文名称', dataIndex: 'sname'},
        {title: '拉丁名称', dataIndex: 'ssciname', scopedSlots: {customRender: 'ssciname'}},
        {title: '相似性', dataIndex: 'pident'}
      ],
      qseqids: [],
      aligns: {},
      qseqid: '',
      align: [],
      loading: false
    };
  },
  methods: {
    onChangeQseqid(qseqid) {
      if (this.qseqid === qseqid) {
        return;
      }
      this.qseqid = qseqid;
      this.align = [...this.aligns[qseqid]];
    }
  },
  beforeMount() {
    getAlignResult(this.$route.params.taskId).then(res => {
      this.qseqids = res.data.data.qseqids;
      this.aligns = res.data.data.aligns;
      this.qseqid = this.qseqids[0];
      this.align = this.aligns[this.qseqid];
    });
  }
}
</script>

<style lang="stylus" scoped>
#main-content
  padding-top: 2rem

  .seqs
    list-style: none
    padding: 0

    li
      height: 2rem
      line-height: 2rem

    li:hover
      cursor: pointer
      background: #CCC
      color: #FFF
</style>
