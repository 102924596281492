import service from '@/utils/service';


export function createAlign(seq) {
  return service({
    method: 'post',
    url: '/v1/aligns/',
    data: {seq}
  });
}


export function getAlignStatus(taskId) {
  return service({
    method: 'get',
    url: '/v1/aligns/' + taskId + '/status/'
  });
}


export function getAlignResult(taskId) {
  return service({
    method: 'get',
    url: '/v1/aligns/' + taskId + '/result/'
  });
}